import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

import Layout from '../components/Layout';

import { colors } from '../helpers/variables';
import { pseudoStateClasses } from '../helpers/stylehelpers';

/** Content */
const Content = styled.div`
    color: ${colors.black};
    padding: ${rem(20)};
    max-width: ${rem(600)};
    margin: 0 auto;

    h1,
    h2 {
        margin-bottom: 0.15em;
    }
`;

const Link = styled.a`
    color: ${colors.black};
    text-decoration: none;
    transition: color 0.25s;

    ${pseudoStateClasses`
        color: ${colors.black};
        text-decoration: underline;
    `};
`;

/**
 * Die Komponente der Startseite
 * @param {Object} data Daten aus der GraphQL-Query
 */
const ImprintPage = () => {
    return (
        <Layout
            title="Schönheit | Vitalität | Wohlbefinden - Startseite"
            description="Manuela Costanzo | Schönheit | Vitalität | Wohlbefinden"
        >
            {/* eslint-disable */}
            <Content>
                <div>
                    <h1>Impressum</h1>
                    <h2>Herausgeber & inhaltlich verantwortlich</h2>
                    <strong>Manuela Costanzo</strong>
                    <br />
                    Schönheit | Vitalität | Wohlbefinden
                    <br />
                    Karl-Krische-Straße 4
                    <br />
                    71522 Backnang
                </div>
                <div>
                    <h2>Kontakt</h2>
                    Tel.:
                    <Link href="tel: +49 1522 3558495"> +49 1522 3558495</Link>
                    <br />
                    <Link href="mailto:info@manuela-costanzo.de">info@manuela-costanzo.de</Link>
                </div>
                <div>
                    <h1>Datenschutz</h1>
                    <p>
                        Der Schutz Ihrer Privatsphäre ist uns wichtig, weshalb wir Sie darüber
                        informieren möchten, welche Maßnahmen und Grundsätze bei der
                        Datenverarbeitung dieser Website angewendet werden.
                    </p>
                    <p>
                        Unterschieden wird hierbei zwischen Zugriffsdaten und personenbezogenen
                        Daten. Zugriffsdaten werden bei der allgemeinen Nutzung unserer Website
                        automatisch erhoben und gespeichert, wohingegen personenbezogene Daten nur
                        auf freiwilliger Basis und mit Ihrer Erlaubnis gespeichert und verarbeitet
                        werden.
                    </p>
                    <p>
                        Mit der Zustimmung zu der nachfolgenden Datenschutzerklärung willigen Sie
                        gegenüber <strong>Manuela Costanzo</strong> in die Erhebung, Verarbeitung und Nutzung
                        Ihrer personenbezogenen Daten unter Beachtung der Datenschutzgesetze und den
                        nachfolgenden Bestimmungen ein.
                    </p>
                </div>
                <div>
                    <h2>1. Veranwortliche Stelle</h2>
                    <p>
                        Falls Sie der Erhebung, Verarbeitung oder Nutzung Ihrer Daten durch die{' '}
                        <strong>Manuela Costanzo</strong> entsprechend der nachfolgenden
                        Datenschutzbestimmungen insgesamt oder für einzelne Maßnahmen widersprechen
                        wollen, richten Sie Ihren Widerspruch bitte per E-Mail, Fax oder Brief an:
                    </p>
                    <p>
                        <strong>Manuela Costanzo</strong>
                        <br />
                        Schönheit | Vitalität | Wohlbefinden <br />
                        Karl-Krische-Straße 4
                        <br />
                        71522 Backnang
                    </p>
                </div>
                <div>
                    <h2>
                        2. Zwecke der Datenverarbeitung, Rechtsgrundlage sowie berechtigte
                        Interessen des für die Verarbeitung Verantwortlichen und Dauer der
                        Speicherung
                    </h2>
                    <h3>2.1 Allgemeine Hinweise zur Datenverarbeitung</h3>
                    <p>
                        Die Nutzung unserer Internetseiten ist in der Regel ohne Angabe
                        personenbezogener Daten möglich. Wir erheben Ihre personenbezogenen Daten
                        nur, soweit dies zur Bereitstellung unseres Internetangebots sowie unserer
                        Leistungen erforderlich ist. Soweit Sie auf unseren Seiten personenbezogene
                        Daten (beispielsweise Name, Anschrift, E-Mail-Adresse oder Telefonnummer)
                        angeben, erfolgt dies grundsätzlich auf freiwilliger Basis. Ohne Ihre
                        Einwilligung verarbeiten wir Ihre Daten grundsätzlich nur, soweit uns eine
                        vorherige Einholung Ihrer Einwilligung aus tatsächlichen Gründen nicht
                        möglich und die Verarbeitung der Daten durch gesetzliche Vorschriften
                        ausdrücklich gestattet ist.
                    </p>
                    <p>
                        <strong>
                            Rechtsgrundlagen der Verarbeitung Ihrer personenbezogenen Daten sind:
                        </strong>
                    </p>
                    <ul>
                        <li>
                            Art. 6 Abs. 1 lit. a) DSGVO, soweit Sie ausdrücklich in die Verarbeitung
                            Ihrer Daten zu einem bestimmten Zweck eingewilligt haben,
                        </li>
                        <li>
                            Art. 6 Abs. 1 lit. b) DSGVO, soweit die Verarbeitung Ihrer Daten zur
                            Erfüllung eines Vertrages, dessen Vertragspartei Sie sind, erforderlich
                            ist sowie zur Durchführung vorvertraglicher Maßnahmen,
                        </li>
                        <li>
                            Art. 6 Abs. 1 lit. c) DSGVO, soweit die Erfüllung Ihrer Daten zur
                            Erfüllung rechtlicher Verpflichtungen erforderlich ist sowie
                        </li>
                        <li>
                            Art. 6 Abs. 1 lit. f) DSGVO, soweit die Verarbeitung zur Wahrung eines
                            berechtigten Interesses unseres Unternehmens oder eines Dritten
                            erforderlich ist und entgegenstehende Interessen und Grundrechte der
                            betroffenen Person nicht überwiegen.
                        </li>
                    </ul>
                    <p>
                        Personenbezogene Daten werden gelöscht oder in ihrer Verarbeitung
                        eingeschränkt, sobald der Zweck der Verarbeitung entfällt, soweit nicht
                        gesetzliche, vertragliche oder satzungsmäßige Vorgaben die Aufbewahrung der
                        Daten erfordern.
                    </p>
                    <p>
                        <strong>
                            In folgenden Fällen erfolgt eine Verarbeitung personenbezogener Daten:
                        </strong>
                    </p>
                    <h3>2.2 Aufrufen unserer Internetseiten</h3>
                    <p>
                        Systembedingt werden beim Aufrufen unserer Internetseiten Informationen in
                        sogenannten Server-Log-Files erhoben und gespeichert, die Ihr Browser
                        automatisch an uns übermittelt. Dies sind:
                    </p>
                    <ul>
                        <li>Anonymisierte IP-Adresse des abrufenden Endgeräts,</li>
                        <li>Datum und Uhrzeit des Zugriffs,</li>
                        <li>Name und URL der abgerufenen Datei,</li>
                        <li>
                            Referrer-URL (Internetseite, von der aus der Zugriff erfolgte) und
                            aufgerufene Links,
                        </li>
                        <li>
                            Information über den Browsertyp, die verwendete Version und ggf. das von
                            Ihnen verwendete Betriebssystem sowie der Name Ihres Access-Providers.
                        </li>
                    </ul>
                    <p>
                        Diese Daten sind für uns nicht bestimmten Personen zuordenbar, Rückschlüsse
                        auf Ihre Identität sind uns nicht möglich. Die Daten der Logfiles werden
                        stets getrennt von anderen personenbezogenen Daten gespeichert; eine
                        Zusammenführung mit anderen Datenquellen erfolgt nicht.
                    </p>
                    <p>
                        Diese Daten werden auf der Grundlage von Art. 6 Abs. 1 Buchstabe f) DSGVO
                        verarbeitet zur Auslieferung der Inhalte unseres Internetauftrittes sowie
                        zur Gewährleistung der Funktionsfähigkeit unserer informationstechnischen
                        Systeme, zur Optimierung unserer Internetseiten sowie zur Auswertung der
                        Systemsicherheit und -stabilität.
                    </p>
                    <p>
                        Die Daten werden für einen Zeitraum von maximal 30 Tagen gespeichert und
                        anschließend automatisch gelöscht.
                    </p>
                    <p>
                        Eine Löschung Ihrer personenbezogenen Daten erfolgt, sofern gesetzliche
                        Aufbewahrungspflichten dem nicht entgegenstehen und wenn Sie einen
                        Löschungsanspruch geltend gemacht haben, wenn die Daten zur Erfüllung des
                        mit der Speicherung verfolgten Zwecks nicht mehr erforderlich sind oder wenn
                        ihre Speicherung aus sonstigen gesetzlichen Gründen unzulässig ist.
                    </p>
                </div>
                <div>
                    <h2>3. Betroffenenrechte</h2>
                    <p>
                        Sofern wir Ihre personenbezogenen Daten verarbeiten, stehen Ihnen die
                        folgenden Betroffenenrechte nach Kapitel 3 DSGVO zu:
                    </p>
                    <h3>3.1 Widerspruchsrecht</h3>
                    <p>
                        Sie können der Verarbeitung Ihrer persönlichen Daten widersprechen, soweit
                        diese aufgrund von Art. 6 Abs. 1 Buchstabe f) DSGVO („berechtigte Zwecke“)
                        gestützt wird (Art. 21 DSGVO). Eine Verpflichtung zur Umsetzung Ihres
                        Widerspruchs besteht nach Art. 21 Abs. 1 Satz 1 DSGVO jedoch nur, wenn Sie
                        uns hierfür Gründe von übergeordneter Bedeutung nennen, die sich aus ihrer
                        besonderen Situation ergeben. Der Verarbeitung Ihrer persönlichen Daten zu
                        Werbe-/Marketingzwecken können Sie jederzeit – ohne Angabe von Gründen –
                        widersprechen
                    </p>
                    <h3>
                        3.2 Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung
                    </h3>
                    <p>
                        Sie haben das Recht, Ihre datenschutzrechtliche Einwilligung jederzeit ohne
                        Angabe von Gründen zu widerrufen. Durch den Widerruf der Einwilligung wird
                        die Rechtmäßigkeit der aufgrund der erklärten Einwilligung bis zum Widerruf
                        erfolgten Verarbeitung nicht berührt.
                    </p>
                    <h3>3.3 Weitere Betroffenenrechte</h3>
                    <p>
                        Zudem können Sie – bei Vorliegen der jeweiligen gesetzlichen Voraussetzungen
                        – folgende Rechte in Anspruch nehmen:
                    </p>
                    <ul>
                        <li>
                            Recht auf Auskunft über Ihre bei uns gespeicherten persönlichen Daten,
                            insb. über die Verarbeitungszwecke, die Kategorien der Daten und deren
                            Herkunft, falls die Daten nicht direkt bei Ihnen erhoben wurden, die
                            Kategorien der Empfänger Ihrer Daten, die geplante Speicherdauer sowie
                            Ihre Betroffenenrechte (Art. 15 DSGVO),
                        </li>
                        <li>
                            Recht auf Berichtigung unrichtiger oder unvollständiger
                            personenbezogener Daten (Art. 16 DSGVO),
                        </li>
                        <li>
                            Recht auf Löschung Ihrer persönlichen Daten, insb. wenn die Daten für
                            die Zwecke, für die sie erhoben wurden, nicht mehr notwendig sind und
                            wir nicht aufgrund gesetzlicher, satzungsmäßiger oder vertraglicher
                            Vorgaben zur Aufbewahrung der Daten verpflichtet sind (Art. 17 DSGVO),
                        </li>
                        <li>
                            Recht auf Einschränkung der Verarbeitung Ihrer persönlichen Daten (Art.
                            18 DSGVO),
                        </li>
                        <li>
                            Recht auf Datenübertragbarkeit hinsichtlich der uns von Ihnen
                            bereitgestellten persönlichen Daten in einem gängigen, maschinenlesbaren
                            Format (Art. 20 DSGVO).
                        </li>
                    </ul>
                    <h3>3.4 Recht auf Beschwerde bei einer Aufsichtsbehörde</h3>
                    <p>
                        Nach Art. 77 DSGVO haben Sie unbeschadet anderweitiger
                        verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe das Recht auf
                        Beschwerde bei einer Aufsichtsbehörde, wenn sie der Ansicht sind, dass die
                        Verarbeitung der Sie betreffenden Daten durch uns gegen die Vorgaben der
                        DSGVO verstößt.
                    </p>
                </div>
                <div>
                    <h2>4. Datensicherheit</h2>
                    <p>
                        Wir treffen angemessene technische Sicherheitsmaßnahmen, um die uns von
                        Ihnen anvertrauten Daten vor Verlust, Zerstörung, Offenlegung und Zugriff
                        durch Unbefugte zu schützen und passen diese stets den technischen
                        Weiterentwicklungen an. So setzen wir zum Schutz Ihrer Daten, insbesondere
                        bei der Kommunikation über unsere Internetseiten eine SSL-Verschlüsselung
                        ein (
                        <a
                            href="https://letsencrypt.org/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Lets Encrypt
                        </a>
                        ).
                    </p>
                    <p>
                        Wir weisen allerdings darauf hin, dass die Datenübertragung im Internet
                        (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
                        Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
                        möglich.
                    </p>
                </div>
                <div>
                    <h2>5. Links zu externen Websites</h2>
                    <p>
                        Unsere Website enthält Links zu externen Websites. Wir haben jedoch keinen
                        Einfluss auf die Einhaltung der Datenschutzbestimmungen seitens deren
                        Betreiber. Als Anbieter sind wir für eigene Inhalte nach den allgemeinen
                        Gesetzen verantwortlich. Zwischen unseren Inhalten und von anderen Anbietern
                        bereitgestellten Inhalten, auf die von uns verlinkt wird, ist hierbei zu
                        unterscheiden. Für fremde Inhalte, die über entsprechende Links zur Nutzung
                        zugänglich gemacht werden, übernehmen wir keinerlei Verantwortung. Für
                        illegale, fehlerhafte oder unvollständige Inhalte sowie Schäden, die durch
                        die Nutzung oder Nichtnutzung dieser Informationen entstehen, haftet alleine
                        der Anbieter der Website, auf die verwiesen wurde.
                    </p>
                </div>
                <div>
                    <h2>Haben Sie noch Fragen oder Wünsche?</h2>
                    <p>
                        Ihr Vertrauen ist uns wichtig. Daher möchten wir Ihnen jederzeit Rede und
                        Antwort bezüglich der Verarbeitung Ihrer personenbezogenen Daten stehen.
                        Weitere Anregungen zum Thema Datenschutz sind uns ebenfalls sehr wichtig und
                        jederzeit willkommen. Wenn Sie Fragen haben, die Ihnen diese
                        Datenschutzerklärung nicht beantworten konnte oder wenn Sie zu einem Punkt
                        tiefergehende Informationen wünschen, so helfen wir Ihnen gerne weiter.
                        Kontaktieren Sie uns in diesem Fall einfach über die zuvor genannte Adresse.
                    </p>
                </div>
                {/* eslint-enable */}
            </Content>
        </Layout>
    );
};

export default ImprintPage;
